import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../about.css"
const Privacy = () => {
  return (
    <Layout>
      <SEO title={`About Us `} />
      <div className="main">
        <div className="container">
          <div className="section_posting">
            <div className="box">
              <h1>What is AddFriends?</h1>
              <p>
                We maximize profits from your media <strong>and</strong> put
                time back in your day so you can create and cultivate your fan
                base. We do what we do, so you can do what you do. How?
              </p>
              <h5 className="abouth5">How?</h5>
              <p>+ Add Friends Channel (private Snapchat may be included)</p>
              <p className="leftPad">
                Your AF channel is built for speed and convenience. Your fans
                will have instant problem free access to your media. Member
                retention is our speciality.
              </p>
              <p>+ Find your fans</p>
              <p className="leftPad">
                We have proven methods of finding your fans and funneling them
                to your social media so you can market to them directly. Turn
                those surfers into profits.
              </p>
              <p>+ Distribution</p>
              <p className="leftPad">
                In addition to your AddFriends channel, we can distribute your
                media to any other pay platform that you already use. Simply
                upload to AddFriends, tell us where else you want that media
                distributed, and we'll take it from there. Yes, one upload is
                all it takes.
              </p>
              <br />
              <br />
              <h5 className="abouth5">I'm interested, but I have questions.</h5>
              <p>
                Excellent. Reach out to us via our Contact Form here or send us
                an email:{" "}
                <a href="mailto:hello@addfriends.com" className="aboutLink">
                  hello@addfriends.com
                </a>
                . Please include a picture of your photo ID and links to your
                existing social media - then ask away. The only foolish
                questions are the ones left unasked, we're at your disposal.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Privacy
